<script setup>
//import avatar from '@/assets/images/avatars/2.jpg'
const avatar = '/favicon.png'

const itemsCount = 69
</script>

<template>
  <CDropdown placement="bottom-end" variant="nav-item">
    <CDropdownToggle class="py-0 pe-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold mb-2 rounded-top nascosto"
      >
        Account
      </CDropdownHeader>
      <CDropdownItem class="nascosto">
        <CIcon icon="cil-bell" /> Notifiche
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem class="nascosto">
        <CIcon icon="cil-envelope-open" /> Messaggi
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem class="nascosto">
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem class="nascosto">
        <CIcon icon="cil-comment-square" /> Commenti
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold my-2"
      >
        Settings
      </CDropdownHeader>
      <CDropdownItem class="nascosto"> <CIcon icon="cil-user" /> Profilo </CDropdownItem>
      <CDropdownItem class="nascosto"> <CIcon icon="cil-settings" /> Impostazioni </CDropdownItem>
      <CDropdownDivider class="nascosto" />
      <CDropdownItem class="nascosto"> <CIcon icon="cil-shield-alt" /> Sicurezza Profilo </CDropdownItem>
      <a @click.prevent="logout"><CDropdownItem> <CIcon icon="cil-lock-locked" /> Logout</CDropdownItem></a>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
export default {
  name: 'AppHeaderDropdownAccnt',
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.isLoggedIn = false;
      this.$router.push('/login');
    }
  }
}
</script>