<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Report Ore</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form @submit.prevent="generateReport">
                            <!-- Data inizio -->
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Data inizio</label>
                                <div class="input-group">
                                    <input 
                                        type="date" 
                                        id="startDate" 
                                        class="form-control" 
                                        v-model="startDate"
                                    >
                                </div>
                            </div>

                            <!-- Data fine -->
                            <div class="mb-3">
                                <label for="endDate" class="form-label">Data fine</label>
                                <div class="input-group">
                                    <input 
                                        type="date" 
                                        id="endDate" 
                                        class="form-control" 
                                        v-model="endDate"
                                    >
                                </div>
                            </div>

                            <!-- Tipo utente -->
                            <div class="mb-3">
                                <label for="userType" class="form-label">Tipo utente</label>
                                <select 
                                    id="userType" 
                                    class="form-select" 
                                    v-model="userType"
                                >
                                    <option value="" disabled>Seleziona tipo</option>
                                    <option value="3">Dipendenti</option>
                                    <option value="4">Collaboratori</option>
                                </select>
                            </div>

                            <!-- Pulsante di invio -->
                            <button type="submit" class="btn btn-primary w-100">Scarica report</button>
                        </form>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>

    
<script>
    //import { CIcon } from '@coreui/icons-vue';
    import { Device } from '@capacitor/device';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    
    export default {
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                startDate: '', // Data in formato YYYY-MM-DD
                endDate: '', // Data in formato YYYY-MM-DD
                userType: '', // Tipo di utente selezionato
                accessDeniedMessage: '', // Messaggio per accesso negato
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    //alert('Accesso negato: Non hai i permessi per accedere a questa pagina.');
                    //this.$router.push('/dashboard');
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
            },
            async generateReport() {
                // Temp logs
                console.log('Genera il report con i seguenti dati:');
                console.log('Data inizio:', this.startDate);
                console.log('Data fine:', this.endDate);
                console.log('Tipo utente:', this.userType);

                if (!this.startDate || !this.endDate || !this.userType) {
                    alert('Compila tutti i campi prima di scaricare il report!');
                    return;
                }

                try {
                    const queryParams = new URLSearchParams({
                        startDate: this.startDate,
                        endDate: this.endDate,
                        userType: this.userType,
                    }).toString();

                    const token = localStorage.getItem('token'); // Recupera il token di autenticazione

                    const response = await fetch(`https://toothtime.it/api/reports/hours?${queryParams}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) throw new Error('Errore durante la generazione del report.');

                    // Generazione e download del file CSV
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'report_ore.csv'; // Nome del file temporaneo
                    a.click();
                    window.URL.revokeObjectURL(url);

                } catch (error) {
                    console.error('Errore:', error);
                    alert('Errore durante il download del report.');
                }
            },
        }
    };
</script>