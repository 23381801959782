<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Cassetto Documenti</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="documents.length === 0" class="text-center">
                            <p>Nessun documento disponibile.</p>
                        </div>
                        <div v-else>
                            <div v-for="(docs, folder) in groupedDocuments" :key="folder" class="mb-5">
                                <h3>{{ folderNames[folder] || folder || 'Senza cartella' }}</h3>
                                <table class="table table-striped table-hover mb-5">
                                    <thead>
                                        <tr>
                                            <!--<th scope="col">Cartella</th>-->
                                            <th scope="col">Nome Documento</th>
                                            <th scope="col">Data Caricamento</th>
                                            <th scope="col">Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="doc in docs" :key="doc.id_doc">
                                            <!--<td>{{ doc.path }}</td>-->
                                            <td>{{ doc.name }}</td>
                                            <td>{{ formatDate(doc.upload_date) }}</td>
                                            <td>
                                                <!--<a :href="doc.file_url" target="_blank" class="btn btn-primary btn-sm"><CIcon icon="cil-cloudDownload" /></a>-->
                                                <a
                                                    :href="doc.file_url"
                                                    target="_blank"
                                                    class="btn btn-primary btn-sm"
                                                    @click.prevent="handleDownload(doc.id_doc, doc.file_url)"
                                                >
                                                    <CIcon icon="cil-cloudDownload" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>
  
<script>
    //import { CIcon } from '@coreui/icons-vue';
    import { Device } from '@capacitor/device';
    import studioMixin from '@/mixins/studioMixin';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    
    export default {
        mixins: [studioMixin],
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                accessDeniedMessage: '', // Messaggio per accesso negato
                studioId: null,
                documents: [],
                userRole: null,
                userId: null,
                folderNames: {
                    'bustepaga': 'Buste Paga',
                    'contratti': 'Contratti',
                    'documentigenerali': 'Documenti Generali',
                },
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
            this.fetchDocuments();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                /*
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
                */
            },
            async fetchDocuments() {
                try {
                    const response = await fetch(`https://toothtime.it/api/documents/${this.getStudioIdFromToken()}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error('Errore nel recupero dei documenti.');
                    }
                    this.documents = await response.json();  // Converte la risposta in JSON e la assegna all’elenco dei documenti
                    ////console.log('Filtered Documents:', this.filteredDocuments);
                } catch (error) {
                    console.error('Errore durante il caricamento dei documenti:', error);
                }
            },
            formatDate(date) {
                if (!date) return 'N/A';  // Gestione del valore null
                const formattedDate = date.replace(' ', 'T');  // Sostituisce lo spazio con "T"
                return new Date(formattedDate).toLocaleDateString('it-IT', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            },
            async logDownload(documentId) {
                try {
                    const response = await fetch('https://toothtime.it/api/logs/download', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            document_id: documentId,
                        }),
                    });
                    if (!response.ok) {
                        throw new Error('Errore durante la registrazione del log di download.');
                    }
                    //console.log('Log di download registrato con successo');
                } catch (error) {
                    console.error('Errore durante la registrazione del log di download:', error);
                }
            },
            handleDownload(documentId, fileUrl) {
                this.logDownload(documentId); // Registra il log
                window.open(fileUrl, '_blank'); // Apre il file
            },
        },
        computed: {
            filteredDocuments() {
                //console.log('Studio ID:', this.getStudioIdFromToken());  // Debug per assicurarti che `studioId` sia corretto
                //console.log('Ruolo utente:', this.userRole);
                //console.log('Documenti totali:', this.documents.length);

                if (!this.documents || this.documents.length === 0) {
                    return [];  // Se non ci sono documenti
                }

                // Filtro per mostrare solo i documenti dello studio selezionato
                return this.documents.filter(doc => doc.id_studio == this.getStudioIdFromToken());
            },
            groupedDocuments() {
                return this.filteredDocuments.reduce((acc, doc) => {
                    const folder = doc.path || 'Senza cartella';  // Considera "Senza cartella" se il path è null
                    if (!acc[folder]) {
                        acc[folder] = [];  // Crea un array vuoto per la cartella
                    }
                    acc[folder].push(doc);
                    return acc;
                }, {});
            },
        }
    };
</script>