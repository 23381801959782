import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import StudioConfig from '@/views/studio/StudioConfig.vue';
import Badge from '@/views/modules/BadgeModule.vue';
import BadgeBreak from '@/views/modules/BadgeBreakModule.vue';
import Report from '@/views/modules/ReportModule.vue';
import DocsUp from '@/views/modules/AdminDocuments.vue';
import Docs from '@/views/modules/DocsModule.vue';
import Squeeze from '@/views/webpages/SqueezePage.vue';
import SqueezeContact from '@/views/webpages/SqueezeContact.vue';
import Tnku from '@/views/webpages/ThankyouPage.vue';
import Privacy from '@/views/webpages/PrivacyPage.vue';
import Cookie from '@/views/webpages/CookiePage.vue';

const routes = [
  { path: '/login', component: LoginPage },
  { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/theme/config', component: StudioConfig, meta: { requiresAuth: true } },
  { path: '/module/badge', component: Badge, meta: { requiresAuth: true } },
  { path: '/module/badge-break', component: BadgeBreak, meta: { requiresAuth: true } },
  { path: '/module/report', component: Report, meta: { requiresAuth: true } },
  { path: '/module/docs-up', component: DocsUp, meta: { requiresAuth: true } },
  { path: '/module/docs', component: Docs, meta: { requiresAuth: true } },
  { path: '/', redirect: '/login' },
  //{ path: '/top50', component: Squeeze },
  { path: '/lista-attesa', component: Squeeze },
  { path: '/richiesta-contatto', component: SqueezeContact },
  { path: '/thankyou', component: Tnku },
  { path: '/privacy-policy', component: Privacy },
  { path: '/cookie-policy', component: Cookie },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Middleware per la protezione delle rotte
/*
router.beforeEach((to, from, next) => {
  //console.log('Tentativo di accesso a:', to.path);
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    console.log('Accesso NON autorizzato a:', to.path);
    next('/login');
  } else {
    console.log('Accesso autorizzato a:', to.path);
    next();
  }
});
*/
router.beforeEach(async (to, from, next) => {
  // Controlla se la rotta richiede autenticazione
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('token');

    if (!token) {
      // Token assente, reindirizza al login
      console.log('Token assente, reindirizzo al login.');
      return next('/login');
    }

    try {
      // Verifica il token lato server
      const response = await fetch('https://toothtime.it/api/auth/validate', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Token valido, prosegui
        ////console.log('Token valido, accesso autorizzato a:', to.path);
        return next();
      } else {
        // Token non valido o scaduto
        ////console.log('Token non valido o scaduto, reindirizzo al login.');
        localStorage.removeItem('token');
        return next('/login');
      }
    } catch (error) {
      // Errore di rete o altro problema
      ////console.error('Errore durante la verifica del token:', error);
      localStorage.removeItem('token');
      return next('/login');
    }
  }

  // Nessuna autenticazione richiesta, prosegui
  return next();
});

export default router;
