<template>

  <div class="margine-footer"></div>

    <CFooter class="px-4 nascosto">
      <div class="mr-auto">
        <span class="ms-1">
          Powered with ❤️‍🔥 by 
          <a href="https://www.crazyadv.com/" target="_blank">Crazy Adv Srl</a>
        </span>
        <span class="ms-1">
          &copy; {{ new Date().getFullYear() }} ToothTime v 0.0.2
        </span>
      </div>
    </CFooter>

    <div class="footer-mobile-custom">
      <div v-if="isMobile">
        <ion-content class="ion-padding"></ion-content>
        <ion-footer class="ion-no-border">
          <ion-toolbar>
            <a href="/module/badge" class="btn btn-primary"><CIcon icon="cil-alarm" /></a>
            <a href="/dashboard" class="btn btn-primary"><CIcon icon="cil-home" /></a>
            <a href="/module/docs" class="btn btn-primary"><CIcon icon="cil-storage" /></a>
          </ion-toolbar>
        </ion-footer>
      </div>
      <div v-else>
        <ion-content class="ion-padding"></ion-content>
        <ion-footer class="ion-no-border">
          <ion-toolbar> 
            <a href="/module/badge" class="btn btn-primary"><CIcon icon="cil-alarm" /></a>
            <a href="/dashboard" class="btn btn-primary"><CIcon icon="cil-home" /></a>
            <a href="/module/docs" class="btn btn-primary"><CIcon icon="cil-storage" /></a>
          </ion-toolbar>
        </ion-footer>
      </div>
    </div>
    
  </template>
  
<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
  ion-button {
    --background: #97AF8F;
    --background-hover: #B0C2AB;
    --background-activated: #B0C2AB;
    --background-focused: #B0C2AB;

    --color: #1B1B1B;
  }
</style>