<template>
    <ion-page>
        <!-- Hero Section -->
        <div class="hero-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <img class="sidebar-brand-full" src="@/assets/logo_w.png" height="50"/>
                        <h1 class="hero-title">
                            Siamo entusiasti di presentarti ufficialmente ToothTime!
                        </h1>
                        <p class="hero-subtitle">
                            L’app che ti aiuterà nella gestione del tempo delle tue risorse umane.
                        </p>
                        <p class="hero-subtitle">
                            Guarda il video qui sotto ⬇️
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Video Section -->
        <div class="container-fluid mt-5">
            <div class="row justify-content-center text-center">
                <div class="col-md-12">
                    <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
                        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" 
                            src="https://www.youtube.com/embed/PNuuV7Mhezk?si=te5F57-VWb6BxSW1&rel=0&modestbranding=1" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>

        <!-- Description Section -->
        <div class="container mt-5">
            <div class="row justify-content-center text-center">
                <div class="col-md-6">
                    <p>
                        <strong>Ora che hai scoperto ToothTime e tutto ciò che può fare per il tuo studio dentistico, hai due opzioni:</strong>
                    </p>
                    <p>
                        Continuare con i metodi tradizionali, spesso inefficaci e dispendiosi.
                    </p>
                    <p>
                        Oppure <strong>lasciare i tuoi dati qui sotto per essere contattato e scoprire come integrare ToothTime nel tuo studio</strong>, riprendendoti il tuo tempo con una gestione efficace del tempo delle tue risorse.
                    </p>
                    <p>
                        La scelta è tua. Ed é proprio ad un click di distanza (qui sotto) ⬇️
                    </p>
                </div>
            </div>
        </div>

        <!-- Form Section -->
        <div class="container mt-5 mb-5">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form action="https://app.getresponse.com/add_subscriber.html" class="form-section" accept-charset="utf-8" method="post">
                        <div class="mb-3">
                            <label for="name" class="form-label">NOME</label>
                            <input type="text" name="name" class="form-control" id="name" placeholder="Inserisci il tuo nome" required>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">EMAIL</label>
                            <input type="email" name="email" class="form-control" id="email" placeholder="Es. nome@example.com" required>
                        </div>
                        <div class="mb-3">
                            <label for="custom_telefononopref" class="form-label">TELEFONO</label>
                            <input type="text" name="custom_telefononopref" class="form-control" id="custom_telefononopref" placeholder="Inserisci il tuo contatto telefonico" required>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="true" id="webform_consent#GDGs_0" name="webform[consent#GDGs-ver#QeBx]" required>
                            <label class="form-check-label" for="webform_consent#GDGs_0">
                                Acconsento al trattamento dei dati personali e confermo di aver preso visione dell'
                                <a href="https://toothtime.it/privacy-policy" target="_blank" rel="nofollow noopener">informativa privacy</a>
                            </label>
                        </div>
                        <input type="hidden" name="campaign_token" value="ZzcTy" />
                        <input type="hidden" name="thankyou_url" value="https://toothtime.it/thankyou"/>
                        <button type="submit" class="cta-btn w-100">RICHIEDI INFORMAZIONI</button>
                    </form>
                </div>
            </div>
        </div>
    </ion-page>
</template>

<style>
.hero-section {
    background: linear-gradient(135deg, var(--primary-color), var(--step-color));
    color: white;
    padding: 80px 0;
    text-align: center;
}
.hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 1rem;
}
.hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 30px;
}
.cta-btn {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 1px solid;
    border-color: var(--black-color);
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 5px;
    margin-top: 1rem;
}
.cta-btn:hover {
    background-color: var(--step-color);
}
.form-section {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.form-title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 30px;
}
.form-control {
    border-radius: 5px;
}
</style>