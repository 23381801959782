<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Caricamento Documenti</h1>
                        <p v-if="uploadMessage" :class="{'text-success': uploadSuccess, 'text-danger': !uploadSuccess}">{{ uploadMessage }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form @submit.prevent="uploadDocument" class="p-4 border rounded">
                            <div class="mb-3">
                                <label for="name" class="form-label">Nome Documento</label>
                                <input
                                type="text"
                                id="name"
                                v-model="form.name"
                                class="form-control"
                                placeholder="Inserisci nome documento"
                                required
                                />
                            </div>

                            <div class="mb-3">
                                <label for="file_path" class="form-label">Seleziona Categoria (cartella di caricamento)</label>
                                <select v-model="form.file_path" id="file_path" class="form-select" required>
                                    <option value="contratti">Contratti</option>
                                    <option value="bustepaga">Buste Paga</option>
                                    <option value="documentigenerali">Documenti Generali</option>
                                </select>
                            </div>

                            <div class="mb-3">
                                <label for="file" class="form-label">Carica Documento</label>
                                <input
                                type="file"
                                id="file"
                                class="form-control"
                                @change="handleFileUpload"
                                required
                                />
                            </div>

                            <div class="mb-3">
                                <label for="assigned_to" class="form-label">Assegna a (opzionale)</label>
                                <select v-model="form.id_user" id="assigned_to" class="form-select">
                                <option value="">Nessuno (pubblico)</option>
                                <option v-for="user in employees" :key="user.value" :value="user.value">
                                    {{ user.label }}
                                </option>
                                </select>
                            </div>

                            <button type="submit" class="btn btn-primary w-100">Carica Documento</button>
                        </form>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>
  
<script>
    //import { CIcon } from '@coreui/icons-vue';
    import { Device } from '@capacitor/device';
    import studioMixin from '@/mixins/studioMixin';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    
    export default {
        mixins: [studioMixin],
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                accessDeniedMessage: '', // Messaggio per accesso negato
                form: {
                    id_studio: null, // Impostato con l'ID dello Studio
                    id_user: null, // Destinatario del documento (opzionale)
                    path: '',  // Categoria del documento (contratti, buste paga, ecc.)
                    name: '',
                    file_ref: '',
                    file_type: '',
                    file_url: '',
                },
                file: null, // File selezionato
                employees: [], // Lista di utenti associati allo studio
                uploadMessage: '',
                uploadSuccess: false,
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
            this.form.id_studio = this.getStudioIdFromToken();
            this.getEmployees();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    //alert('Accesso negato: Non hai i permessi per accedere a questa pagina.');
                    //this.$router.push('/dashboard');
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
            },
            async getEmployees() {
                try {
                    const response = await fetch(`https://toothtime.it/api/getStudioEmployees/${this.getStudioIdFromToken()}`, {
                        method: 'GET',
                        headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error("Errore nel recupero dei dati.");
                    }
                    this.employees = await response.json();
                } catch (error) {
                    console.error("Errore:", error);
                }
            },
            handleFileUpload(event) {
                this.file = event.target.files[0];
                if (this.file) {

                    if (this.file.type !== 'application/pdf') {
                        this.uploadMessage = 'Errore: puoi caricare solo file in formato PDF.';
                        this.uploadSuccess = false;
                        this.file = null; // Resetta il file
                        event.target.value = ''; // Resetta l'input
                        return;
                    }

                    const maxSizeInMB = 1;
                    if (this.file.size / 1024 / 1024 > maxSizeInMB) {
                        this.uploadMessage = 'Errore: il file supera la dimensione massima di 1 MB.';
                        this.uploadSuccess = false;
                        this.file = null; // Resetta il file
                        event.target.value = ''; // Resetta l'input
                        return;
                    }
                    
                    this.form.file_type = this.file.type;
                    this.form.file_ref = `DOC_${Date.now()}`;
                }
            },
            async uploadDocument() {
                if (!this.file) {
                    this.uploadMessage = 'Seleziona un file prima di caricare.';
                    this.uploadSuccess = false;
                    return;
                }

                const formData = new FormData();
                formData.append('id_studio', this.form.id_studio);
                formData.append('id_user', this.form.id_user || '');
                formData.append('name', this.form.name);
                formData.append('path', this.form.file_path);
                formData.append('file_ref', this.form.file_ref);
                formData.append('file_type', this.form.file_type);
                formData.append('file', this.file);

                /*
                console.log('Dati inviati:', {
                    id_studio: this.form.id_studio,
                    id_user: this.form.id_user,
                    name: this.form.name,
                    file_path: this.form.file_path,
                    file_ref: this.form.file_ref,
                    file_type: this.form.file_type,
                    file: this.file,
                });
                */

                try {
                    const response = await fetch('https://toothtime.it/api/drawerdocs/', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                        body: formData,
                    });
                    if (!response.ok) {
                        throw new Error('Errore durante l\'upload.');
                    }
                    //const result = await response.json();
                    //this.uploadMessage = 'Documento caricato con successo! ID Documento: ' + result.id_doc;
                    this.uploadMessage = 'Documento caricato con successo!';
                    this.uploadSuccess = true;
                    this.form = {
                        id_studio: this.getStudioIdFromToken(),
                        id_user: '',
                        file_path: '',
                        name: '',
                        file_ref: '',
                        file_type: '',
                        file_url: '',
                    };
                    this.file = null;
                } catch (error) {
                    console.error(error);
                    this.uploadMessage = 'Caricamento fallito.';
                    this.uploadSuccess = false;
                }
            },
        },
    };
</script>