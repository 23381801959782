<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <div class="container-fluid">
            <div class="row text-center mb-5">
                <div class="col-md-12">
                    <h1>Selezionare la durata della pausa pranzo odierna</h1>
                </div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-md-6">
                    <select class="form-select" v-model="selectedTime">
                        <option value="0">Nessuna pausa</option>
                        <option value="30">30 minuti</option>
                        <option value="60">60 minuti</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-center flex-wrap">
                        <button type="button" class="btn btn-badge btn-warning" @click="setBadgeType(3)">Conferma Pausa</button>
                    </div>
                    <div v-if="message" :class="'alert alert-' + alerttype + ' mt-3'">{{ message }}</div>
                </div>
            </div>
        </div>
        <AppFooter />
        </div>
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import { Device } from '@capacitor/device';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
    components: {
        AppHeader,
        AppFooter,
        AppSidebar,
    },
    data() {
        return {
            isLoggedIn: false,
            isMobile: false,
            badgeData: {
                typeaction: null,  // Tipo di timbratura (1 = Entrata, 2 = Uscita, 3 = Pausa)
            },
            message: '',
            alerttype: '',
            lastAction: null,
            lastInOut: null,
            selectedTime: 0
        };
    },
    async created() {
        const info = await Device.getInfo();
        this.isMobile = info.platform === 'ios' || info.platform === 'android';
        this.fetchLastTimbratura();
        this.fetchLastInOut();
    },
    mounted() {
        this.checkLogin();
    },
    methods: {
        checkLogin() {
            const token = localStorage.getItem('token');
            this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
            if (!this.isLoggedIn) {
                this.$router.push('/login');
            }
        },
        fetchLastTimbratura() {
            // Recupera l'ultima timbratura dall'API
            fetch('https://toothtime.it/api/ultima-timbratura', {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(response => response.json())
            .then(data => {
                this.lastAction = data.action;  // 'entrata', 'uscita', o null - inizio o fine pausa pranzo
            })
            .catch(error => console.error('Errore nel recupero dell\'ultima timbratura:', error));
        },
        fetchLastInOut() {
            // Recupera gli ultimi 3 ingressi e uscite
            fetch('https://toothtime.it/api/ultimi-inout', {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.actions && data.actions.length > 0) {
                    //console.log(data.actions);
                    this.lastInOut = data.actions;
                } else {
                    console.log('Nessun dato disponibile.');
                    this.lastInOut = [];
                }
            })
            .catch(error => console.error('Errore nel recupero delle ultime giornate:', error));
        },
        setBadgeType(typeaction) {
            this.badgeData.typeaction = typeaction;
            this.alerttype = 'success';
            if (typeaction === 3) {
                this.message = 'Pausa registrata!';
            }
            this.submitBadge();
            this.redirectAfterExit();
        },
        redirectAfterExit() {
            window.location.href = '/dashboard';
        },
        async submitBadge() {
            if (!this.badgeData.typeaction) {
                this.message = 'Seleziona una timbratura!';
                return;
            }
            try {
                const url = 'https://toothtime.it/api/timbratura';
                const method = 'POST';

                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        typeaction: this.badgeData.typeaction,
                        selectedTime: this.selectedTime
                    }),
                });

                if (!response.ok) {
                //console.error('Risposta del server non OK:', responseData);
                throw new Error('Errore durante il salvataggio dello studio.');
                }
                //const data = await response.json();
                window.location.reload();

            } catch (error) {
                console.error('Errore:', error);
                this.alerttype = 'danger';
                this.message = 'Si è verificato un errore. Riprova.';
            }
        }
    }
};
</script>